<header class="fixed-top">
  <nav class="navigation">
    <div class="top-navbar">
      <div class="container-xl d-flex justify-content-between align-items-center">
        <a href="https://gov.md" target="_blank" class="header-item d-flex align-items-center">
          <span class="ps-icon-stema item-icon"></span>
          <span class="item-text">{{ 'header.title' | translate }}</span>
        </a>
        <div class="top-info-bar">
          <div class="lang-selector btn-group" dropdown *ngrxLet="translateFacade.lang$; let currentLang">
            <button id="language-menu-toggle" dropdownToggle type="button" class="header-item btn d-flex align-items-center" aria-controls="language-select">
              <span class="ps-icon-language item-icon"></span>
              <span class="item-text">{{ 'header.lang.' + currentLang | translate }}</span>
            </button>
            <ul id="language-select" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="language-menu-toggle">
              <ng-container *ngFor="let lang of langList">
                <li *ngIf="currentLang != lang" class="dropdown-list-item" role="menuitem" (click)="changeLang(lang)">
                  <span class="dropdown-item" tabindex="0">{{ 'header.lang.' + lang | translate }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
          <!-- <ps-auth-bar class="auth-bar-container"></ps-auth-bar>-->
        </div>
      </div>
    </div>
    <div class="main-navbar">
      <div class="container-xl d-flex justify-content-between">
        <a [routerLink]="['/']" class="site-logo d-flex">
          <img class="logo-image" src="assets/images/logo-new.svg" alt="Logo" />
        </a>
        <div [class.open]="isMenuOpened | async" class="navbar-links">
          <button (click)="toggleMenu($event)" [attr.aria-expanded]="isMenuOpened | async" aria-controls="nav-menu" aria-label="Toggle navigation" class="menu-toggler" type="button">
            <span class="ps-icon ps-icon-close"></span>
            <span class="ps-icon ps-icon-menu"></span>
          </button>
          <ul class="list-inline" id="nav-menu">
            <li class="authorize-item d-md-none">
              <ps-auth-bar class="auth-bar-container"></ps-auth-bar>
            </li>
            <li [class.active]="(urlAfterRedirects | async).includes('events')">
              <a [routerLink]="['events']">{{ 'header.menu.events' | translate }}</a>
            </li>
            <li [class.active]="(urlAfterRedirects | async).includes('catalog')">
              <a [routerLink]="['catalog']">{{ 'header.menu.services' | translate }}</a>
            </li>
            <li [class.active]="(urlAfterRedirects | async).includes('organizations')">
              <a [routerLink]="['organizations']">{{ 'header.menu.organizations' | translate }}</a>
            </li>
            <li [class.active]="(urlAfterRedirects | async).includes('cups')">
              <a [routerLink]="['cups']">{{ 'header.menu.cups' | translate }}</a>
            </li>
            <li [class.active]="(urlAfterRedirects | async).includes('support')">
              <a [routerLink]="['support']">{{ 'header.menu.help' | translate }}</a>
            </li>
            <li class="d-md-none border-0">
              <div class="lang-selector btn-group" dropdown [dropup]="true">
                <button id="mobile-language-menu-toggle" dropdownToggle type="button" class="header-item btn d-flex align-items-center">
                  <span class="ps-icon-language item-icon"></span>
                  <span class="item-text">{{ 'header.lang.' + (translateFacade.lang$ | async) | translate }}</span>
                </button>
                <ul id="mobile-language-select" *dropdownMenu class="dropdown-menu">
                  <ng-container *ngFor="let lang of langList">
                    <li class="dropdown-list-item" role="menuitem" (click)="changeLang(lang)">
                      <span class="dropdown-item" tabindex="0">{{ 'header.lang.' + lang | translate }}</span>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
