import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PspAuthFeatureModule} from '@ps-frontend/psp/auth/feature';
import {PspNotFoundFeatureModule} from '@ps-frontend/psp/not-found/feature';
import {PspSearchDomainModule} from '@ps-frontend/psp/search/domain';
import {PspSharedFeedbackDomainModule} from '@ps-frontend/psp/shared/feedback/domain';
import {PspSharedLayoutModule} from '@ps-frontend/psp/shared/layout';
import {PspSharedUtilStateModule} from '@ps-frontend/psp/shared/util/state';
import {WINDOW_PROVIDERS} from '@ps-frontend/psp/shared/util/tokens';
import {PspTranslateUtilsModule} from '@ps-frontend/psp/translate/utils';
import {SharedChatFeatureModule} from '@ps-frontend/shared/chat/feature';
import {SharedSeoDomainModule} from '@ps-frontend/shared/seo/domain';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RedirectComponent} from './redirect/redirect.component';
import {UrlSerializer} from '@angular/router';
import {UrlWithoutEncodingSerializer} from './url-without-encoding-serializer';

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    PspSharedUtilStateModule,
    HttpClientModule,
    PspTranslateUtilsModule,
    PspSharedLayoutModule,
    PspSearchDomainModule,
    PspNotFoundFeatureModule,
    PspAuthFeatureModule,
    PspSharedFeedbackDomainModule,
    ModalModule.forRoot(),
    /*SharedChatFeatureModule,*/
    SharedSeoDomainModule,
    NgxPermissionsModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [WINDOW_PROVIDERS, {provide: UrlSerializer, useClass: UrlWithoutEncodingSerializer}],
})
export class AppModule {}
