import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlWithoutEncodingSerializer implements UrlSerializer {
  // This class is used to prevent Angular from encoding the URL. Because if the query param is encoded example: %3F instead of ?,
  // the ActivatedRoute will not be able to match the route so the selected category will not selected.

  parse(url: string): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): string {
    const dus = new DefaultUrlSerializer();
    const path = dus.serialize(tree);
    const modifiedPath = path
      .replace(/%3F/g, '?')
      .replace(/%3D/g, '=');
    return modifiedPath;
  }
}
